import Home from '../views/Home';
import Overview from '../views/Overview';
import SubCategory from '../views/subCategory/SubCategory';
import BrandAnalysis from '../views/BrandAnalysis';
import GenderAnalysis from '../views/GenderAnalysis';
import FranchiseOverview from '../views/franchise/FranchiseOverview';
import NikeFranchiseLCA from '../views/franchise/NikeFranchiseLCA';
import SkuOverview from '../views/sku/SkuOverview';
import TopSku from '../views/sku/TopSku';
import NikeProductAttribute from '../views/sku/NikeProductAttribute';
import NikeNewLaunchSku from '../views/sku/NikeNewLaunchSku';
import Explor from '../views/explor/Explor'
import DataUpload from '../views/dataManage/DataUpload';
import DataDownload from '../views/dataManage/DataDownload';
import SkuPpiExport from '../views/dataManage/SkuPpiExport';
import Login from '../views/login/Login';
import ModifyPassword from '../views/login/ModifyPassword';
import ResetPassword from '../views/login/ResetPassword';
import User from '../views/system/User';
import Role from '../views/system/Role';

export const routerWhiteList = ['/reset', '/login', '/modify'];

const routers = [
    {
        path: '/reset',
        component: ResetPassword,
        exact: false,
        name: 'Reset Password',
        children: [],
    },
    {
        path: '/login',
        component: Login,
        exact: false,
        name: 'Log In',
        children: [],
    },
    {
        path: '/',
        component: Home,
        exact: false,
        name: 'MARKET',
        children: [
            {
                path: '/overview',
                component: Overview,
                name: 'MARKET OVERVIEW',
                exact: false,
                permission: 'market_overview:index',
            },
            {
                path: '/sub-category',
                component: SubCategory,
                name: 'Sub-Category Overview',
                exact: false,
                permission: 'sub_category_overview:index',
            },
            {
                path: '/brand',
                component: BrandAnalysis,
                name: 'Brand Analysis',
                exact: false,
                permission: 'brand_analysis:index',
            },
            {
                path: '/franchise-overview',
                component: FranchiseOverview,
                name: 'Franchise Overview',
                exact: false,
                permission: 'franchise_analysis:overview',
            },
            {
                path: '/franchise-lca',
                component: NikeFranchiseLCA,
                name: 'Nike Franchise LCA',
                exact: false,
                permission: 'franchise_analysis:nike',
            },
            {
                path: '/gender',
                component: GenderAnalysis,
                name: 'Gender Analysis',
                exact: false,
                permission: 'gender_analysis:index',
            },
            {
                path: '/sku-overview',
                component: SkuOverview,
                name: 'SKU Overview',
                exact: false,
                permission: 'sku_analysis:overview',
            },
            {
                path: '/top-sku',
                component: TopSku,
                name: 'TOP SKUs',
                exact: false,
                permission: 'sku_analysis:top',
            },
            {
                path: '/nike-attribute',
                component: NikeProductAttribute,
                name: 'NIKE Product Attribute',
                exact: false,
                permission: 'sku_analysis:nike_product',
            },
            {
                path: '/nike-launch',
                component: NikeNewLaunchSku,
                name: 'NIKE New Launch SKU',
                exact: false,
                permission: 'sku_analysis:nike_new',
            },
            {
                path: '/explor',
                component: Explor,
                name: 'Attribute Exploration',
                exact: false,
                permission: 'attribute_exploration:index',
            },
            {
                path: '/data-upload',
                component: DataUpload,
                name: 'Upload Data',
                exact: false,
                permission: 'data_management:upload',
            },
            {
                path: '/data-download',
                component: DataDownload,
                name: 'Download Data',
                exact: false,
                permission: 'data_management:download',
            },
            {
                path: '/sku-ppi-export',
                component: SkuPpiExport,
                name: 'SkuPpiExport',
                exact: false,
                permission: 'data_management:ppi',
            },
            {
                path: '/modify',
                component: ModifyPassword,
                name: 'Modify User Info',
                exact: false,
            },
            {
                path: '/user',
                component: User,
                name: 'User Management',
                exact: false,
                permission: 'user:management',
            },
            {
                path: '/role',
                component: Role,
                name: 'Role Management',
                exact: false,
                permission: 'role_permission:management',
            },
        ],
    },
];

export default routers;
