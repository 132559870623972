import * as React from 'react';
import { Menu } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import cx from 'classnames';
import '../../assets/css/home.css';
import './datePicker.css';
import moment from 'moment';
import { transformSingleDate } from '../../utils/index';

import { DatePicker } from 'antd';

const { RangePicker } = DatePicker;

const StyledMenu = withStyles({
    paper: {
        background: '#000',
        color: '#fff',
        borderRadius: '10px',
        '& .MuiList-padding': {
            padding: 0,
        },
    },
})((props) => <Menu getContentAnchorEl={null} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} transformOrigin={{ vertical: 'top', horizontal: 'center' }} {...props} />);

const DATE_TYPES = ['MONTH', 'WEEK'];

const DownloadDatePicker = ({ onConfirm = () => {}, currentStart, currentEnd, currentOrigin, dayRange, error, monthRange, weekRange, dType }) => {
    const [anchorEle, setAnchorEle] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [selected, setSelected] = React.useState([]);
    const [original, setOriginal] = React.useState([]);
    const [dateType, setDateType] = React.useState('MONTH');
    
    React.useEffect(() => {
        setDateType(dType || 'MONTH')
    }, [dType])
    
    const handleCancel = () => {
        setAnchorEle(null);
        setOpen(false);
    };

    const handleConfirm = () => {
        if (selected.length === 2) {
            setAnchorEle(null);
            setOpen(false);
            onConfirm(selected, original, dateType);
        }
    };

    const handleChange = (data, formattedData) => {
        setSelected(formattedData);
        setOriginal(data);
    };

    const disabledDate = (current) => {
        const isFuture = current && current > moment().endOf(dateType);
        if (isFuture) {
			return isFuture;
		}

        if (dateType === 'MONTH' && monthRange) {
            const end = monthRange.max ? transformSingleDate(monthRange.max, 'month') : null;
            const start = monthRange.min ? transformSingleDate(monthRange.min, 'month') : null;
            const isLater = current && (end ? current > moment(end).endOf(dateType) : current > moment().endOf(dateType));
            const isEarlier = current && (start ? current < moment(start).startOf(dateType) : false);
            return isEarlier || isLater;
        } else if (dateType === 'WEEK' && weekRange) {
            const end = weekRange.max ? transformSingleDate(weekRange.max, 'week') : null;
            const start = weekRange.min ? transformSingleDate(weekRange.min, 'week') : null;
            let isLater = false;
            let isEarlier = false;
            if (end && current) {
                const { year, week } = end;
                const timeStamp = moment(`${year}`).add(week, 'weeks').startOf('week').valueOf()
                if (current.valueOf() >= timeStamp) {
                    isLater = true
                } else {
                    isLater = false
                }
            }
            if (start && current) {
                const { year, week } = start;
                const timeStamp = moment(`${year}`).add(week - 1, 'weeks').startOf('week').valueOf()
                if (current.valueOf() < timeStamp) {
                    isEarlier = true
                } else {
                    isEarlier = false
                }
            }

            return isEarlier || isLater;
        }
    };

    return (
        <div className="picker-box">
            <div
                className="flex align-center hover"
                onClick={(e) => {
                    setAnchorEle(e.currentTarget);
                    setOpen(true);
                    setSelected([]);
                    setOriginal([]);
                }}
            >
                <div className={cx("time-item-box-download flex align-center px10", {
					'time-box-error': error
				})}>
                    <div className="icon-calendar picker-item"></div>
                    <div>{(currentOrigin && currentOrigin.startDate) || ''}</div>
                </div>
                <div className="px8">-</div>
                <div className={cx("time-item-box-download flex align-center px10", {
					'time-box-error': error
				})}>
                    <div className="icon-calendar picker-item"></div>
                    <div>{(currentOrigin && currentOrigin.endDate) || ''}</div>
                </div>
            </div>
            <StyledMenu
                keepMounted
                anchorEl={anchorEle}
                open={Boolean(anchorEle)}
                onClose={() => {
                    setAnchorEle(null);
                    setOpen(false);
                }}
            >
                <div className="px10 picker-content flex flex-column">
                    <div className="flex align-center pb10" style={{ borderBottom: '1px solid rgba(255,255,255,0.1)' }}>
                        {
                            DATE_TYPES.map((type, index) => (
                                <div
                                    className={cx('flex-1 header-items flex align-center justify-center hover', {
                                        'header-active': type === dateType,
                                    })}
                                    key={index}
                                    onClick={() => {
                                        setDateType(type);
                                        if (dateType !== type) {
                                            setSelected([]);
                                            setOriginal([]);
                                        }
                                    }}
                                >
                                    {type}
                                </div>
                            ))
                        }
                    </div>
                    <div style={{ width: '560px', height: '340px', position: 'relative', top: '20px' }}>{<RangePicker picker={dateType.toLowerCase()} value={original} open={open} disabledDate={disabledDate} popupStyle={{ zIndex: 9999 }} onChange={handleChange} />}</div>
                    <div className="flex align-center mt30">
                        <div className="flex-1 footer-button hover flex align-center justify-center" onClick={handleCancel}>
                            Cancel
                        </div>
                        <div className="flex-1 ml10 footer-button hover flex align-center justify-center" onClick={handleConfirm}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledMenu>
        </div>
    );
};

export default DownloadDatePicker;
