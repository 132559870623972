import * as React from 'react';
import { observer, inject } from 'mobx-react';
import SkuPpiExportDatePicker from '../components/SkuPpiExportDatePicker';
import DownloadTable from '../components/DownloadTable';
import StyledDialog from '../components/StyledDialog';
import cx from 'classnames';
import { DropzoneArea } from 'material-ui-dropzone';
import { Tooltip } from '@material-ui/core';
import '../../assets/css/skuPpiExport.css'
import { open, downloadTemplate, handleDateStartEnd } from '../../utils/index';
import { withStyles } from '@material-ui/core/styles';

const StyledTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#343434',
        color: '#fff',
        maxWidth: 300,
        fontSize: '12px',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: '10px',
        padding: '10px',
    },
}))(Tooltip);

@inject('dataManageStore', 'commonStore')
@observer
export default class SkuPpiExport extends React.Component {
    state = {
        showDetails: false,
        dateError: false,
        startDate: null,
        endDate: null,
        originalDate: {},
        file: null,
        isLoading: false,
        generateOpen: false,
        isDisable: false,
        warningOpen: false,
        warningMessage: '',
        dateType: 'MONTH',
        isEdit: true
    }

    async componentWillMount () {
        await this.getHistory(4)
    }

    getHistory = async (type) => {
		await this.props.dataManageStore.dataManageDownloadHistoryListRequest({
			type
		});
	}

    handleGenerateCancel = () => {
        this.setState({
            generateOpen: false,
        });
    };

    handleGenerateConfirm = async () => {
        this.setState({
            generateOpen: false,
            isDisable: true,
		});

        const { startDate, endDate, file, dateType } = this.state;
        try {
            const response = await this.props.dataManageStore.dataManageSkuPpiExportDownloadSubmitRequest({
                attachment: file,
				startDate,
                dateType,
				endDate
            });
            if (response.code === '00000') {
                this.setState({
					isDisable: false,
					showDetails: false,
                    file: null,
                    startDate: null,
                    endDate: null,
                    originalDate: {},
                    dateType: 'MONTH'
                });
                await this.props.dataManageStore.dataManageDownloadHistoryListRequest({
                    type: 4
                });
            }
        } catch (error) {
            this.setState({
                isDisable: false,
            });
        }
    };

    handleDownloadSubmit = () => {
        const { isEdit } = this.state;
        if (!isEdit) {
            this.setState({ generateOpen: true });
        }
    };

    handleDateConfirm = (selected, original, dateType) => {
        const { start, end } = handleDateStartEnd(selected, dateType);
        this.setState({
            startDate: start,
            endDate: end,
            originalDate: {
                startDate: selected[0],
                endDate: selected[1],
                origin: original,
            },
			isEdit: true,
            dateType,
			dateError: false
        });
    };

    handleReset = () => {
        this.setState({
            startDate: null,
            endDate: null,
            originalDate: {},
			isEdit: true,
            // showDetails: false,
			file: null,
            dateType: 'MONTH'
        });
    };

    handleUploadChange = (files) => {
        if (files.length) {
            this.setState({
                file: files[0],
            });
        }
	};

    handleTemplateDownload = async () => {
		const response = await this.props.dataManageStore.dataManageDownloadTemplateRequest()
		if (response) {
			downloadTemplate(response, 'product_code_template')
		}
	}

    handleConfirm = async () => {
        try {
            this.setState({
                isLoading: true,
                showDetails: true
            })
            const { startDate, endDate, file, dateType } = this.state;
            const response = await this.props.dataManageStore.dataManageSkuPpiExportOverview({
                attachment: file,
                startDate,
                dateType,
                endDate
            })
            const { code, message } = response
            if (code === 'DATA_1007' || code === 'DATA_1003' || code === 'DATA_1008') {
                this.setState({
                    isLoading: false,
                    isDisable: false,
                    showDetails: false,
                    warningOpen: true,
                    warningMessage: message,
                })
            } else if (code === '00000') {
                this.setState({
                    isLoading: false,
                    isDisable: false,
                    isEdit: false
                })
            }
        } catch (error) {
            this.setState({
                isLoading: false
            })
        }
    }

    handleDownload = async (item) => {
        const response = await this.props.dataManageStore.dataManageDownloadUrlRequest({
            uid: item.uid,
        });
        if (response.code === '00000') {
            open(response.data, true);
        }
	};

    handleRemove = () => {
        this.setState({
            file: null
        })
    }

    render () {
        const { showDetails, startDate, endDate, originalDate, dateError, file, isLoading, isEdit, generateOpen, isDisable, warningOpen, warningMessage, dateType } = this.state;
        const { skuPpiExportTableData, downloadHistoryList } = this.props.dataManageStore;
        const { timeRangeMonth, timeRangeWeek } = this.props.commonStore;

        return <div className="flex-1 flex flex-column hidden p20 sku-ppi-export-bg">
            <div className="flex align-center justify-between">
                <div className="sku-ppi-export-title"></div>
                {
                    showDetails && (
                        <div className="flex align-center justify-center back-button hover" onClick={() => this.setState({ showDetails: false })}>
                            <div className="back-button-icon mr6"></div>
                            <div>Back</div>
                        </div>
                    )
                }
            </div>
            {
                !showDetails && (
                    <div className="flex-1 hidden border-box sku-ppi-export-box px60 flex flex-column">
                        <div className="sku-ppi-export-box-title flex align-center"><div className="sku-ppi-export-icon"></div>SKU PPI EXPORT</div>
                        <div className="sku-ppi-export-title-desc">Upload File with Product Codes</div>
                        <div className="sku-ppi-export-upload-box">
                            <div className="sku-ppi-export-upload-contain">
                                {
                                    !file && (
                                        <div className="upload-code-box">
                                            <DropzoneArea
                                                filesLimit={1}
                                                showAlerts={false}
                                                maxFileSize={10 * 1024 * 1024}
                                                showPreviewsInDropzone={false}
                                                acceptedFiles={['.xlsx']}
                                                dropzoneText="Drag and drop or browse to choose your Product Code"
                                                onChange={(files) => {
                                                    this.handleUploadChange(files);
                                                }}
                                            />
                                        </div>
                                    )
                                }
                                {
                                    file && (
                                        <div className="flex align-center px24 full-height">
                                            <div className="download-file-icon mr18"></div>
                                            <div className="flex-1 flex flex-column justify-between upload-file-text">
                                                <div>{(file && file.name) || ''}</div>
                                            </div>
                                            <div className="sku-ppi-export-icon-remove" onClick={() => this.handleRemove()}></div>
                                        </div>
                                    )
                                }
                            </div>
                            
                            <div className="template-download flex align-center">
                                <div className="mr6">Product Code Template:</div>
                                <div className="mr6 template-hover" onClick={() => this.handleTemplateDownload()}>product_code_template.xlsx</div>
                                <div className="template-icon"></div>
                            </div>
                            <div className="flex align-end sku-ppi-export-box-detail-time">
                                <div className="flex-1 mr14">
                                    <div className="input-category">Time</div>
                                    <div>
                                        <SkuPpiExportDatePicker dType={dateType} error={dateError} onConfirm={this.handleDateConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStart={startDate} currentEnd={endDate} currentOrigin={originalDate}></SkuPpiExportDatePicker>
                                    </div>
                                </div>
                                <div className="sku-ppi-export-box-detail-reset hover" onClick={() => this.handleConfirm()}>Confirm</div>
                            </div>
                        </div>
                        <div className="flex-1 mb20 overflow-y">
                            {downloadHistoryList.map((group, index) => (
                                <div className="flex align-center" key={index}>
                                    {group.map((item, i) => {
                                        return item.uid ? (
                                            <div key={`${index}-${i}`} className={cx("download-table-box flex align-center p20 border-box flex-1 mb20", {
                                                mr20: i === 0
                                            })}>
                                                <div className={cx("mr14", {
													'download-file-calculation': item.downloadStatus === 1,
													'download-file-failed': item.downloadStatus === 3,
													'download-file-icon': item.downloadStatus === 2
												})}></div>
                                                <div className="flex-1 flex flex-column justify-between hidden">
                                                    <StyledTooltip
                                                        placement="top"
                                                        title={
                                                            item.displayName
                                                        }
                                                    >
                                                        <div className="file-title pb10 hidden text-overflow">{item.displayName}</div>
                                                    </StyledTooltip>
                                                    <div className="flex justify-between align-center">
                                                        <div className="flex align-center download-file-time">
                                                            <div>{item.createTime}</div>
                                                        </div>
                                                        {item.downloadStatus === 1 ? (
                                                            <div className="flex align-center">
                                                                <div className="progress-icon mr6"></div>
                                                                <div style={{ color: '#0F751D' }}>In progress...</div>
                                                            </div>
                                                        ) : item.downloadStatus === 2 ? (
                                                            <div className="flex align-center download-button hover" onClick={() => this.handleDownload(item)}>
                                                                <div className="download-button-icon mr6"></div>
                                                                <div>Download</div>
                                                            </div>
                                                        ) : (
                                                            <div style={{ color: '#FF4647' }}>Calculation failed</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div key={`${index}-${i}`} className="flex align-center p20 border-box flex-1 mb20"></div>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
            {
                showDetails && (
                    <div className="flex-1 hidden border-box sku-ppi-export-box-detail px30 flex flex-column">
                        <div className="flex align-end sku-ppi-export-box-detail-time">
                            <div className="flex-1 mr14">
                                <div className="input-category">Time</div>
                                <div>
                                    <SkuPpiExportDatePicker dType={dateType} error={dateError} onConfirm={this.handleDateConfirm} monthRange={timeRangeMonth} weekRange={timeRangeWeek} currentStart={startDate} currentEnd={endDate} currentOrigin={originalDate}></SkuPpiExportDatePicker>
                                </div>
                            </div>
                            <div className="sku-ppi-export-box-detail-reset hover" onClick={() => this.handleConfirm()}>Confirm</div>
                        </div>
                        <div className="flex-1 mb12 mt16 hidden">
                            <DownloadTable isLoading={isLoading} columns={skuPpiExportTableData.columns} data={skuPpiExportTableData.data}></DownloadTable>
                        </div>
                        <div className="flex align-center justify-center mb34">
                            <div
                                className={cx('download-details-button flex align-center justify-center', {
                                    'pointer-disable': isEdit,
                                    hover: !isEdit,
                                })}
                                onClick={() => this.handleDownloadSubmit()}
                            >
                                <div className="download-button-icon mr6"></div>
                                <div>Download</div>
                            </div>
                        </div>
                    </div>
                )
            }
            <StyledDialog open={warningOpen}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="download-caution-icon mb10"></div>
                    <div className="dialog-content-title mb6">CAUTION</div>
                    <div className="dialog-content-desc text-center mb16">{warningMessage}</div>

                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => this.setState({ warningOpen: false, isEdit: true })}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledDialog>
            <StyledDialog open={generateOpen}>
                <div className="dialog-content-box flex flex-column align-center justify-center" style={{ maxWidth: '350px' }}>
                    <div className="download-generate-icon mb10"></div>
                    <div className="dialog-content-title mb6">IN THE GENERATED</div>
                    <div className="dialog-content-desc text-center mb16">It takes about 10-20 minutes to calculate the data. Please be aware that the table will be truncated at 1,000,000 rows.</div>

                    <div className="flex align-center justify-between full-width">
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover mr10" onClick={() => this.handleGenerateCancel()}>
                            Cancel
                        </div>
                        <div className="flex-1 dialog-operator-item flex align-center justify-center hover" onClick={() => !isDisable && this.handleGenerateConfirm()}>
                            Confirm
                        </div>
                    </div>
                </div>
            </StyledDialog>
        </div>
    }
}