import { $get, $post } from './index';

export const dataManageBrandFranchiseRequest = (data) => {
    return $get('/data/dict/brand/franchise/model', data, {
        queryName: 'dataManageBrandFranchiseRequest',
    });
};

export const dataManageAttributeRequest = (data) => {
    return $post('/data/dict/download/attribute', data, {
        queryName: 'dataManageAttributeRequest',
    });
};

export const dataManageDownloadHistoryListRequest = (data) => {
    return $get(
        '/data/download/history/list',
        data,
        {
            queryName: 'dataManageDownloadHistoryListRequest',
        }
    );
};

export const dataManageDownloadUrlRequest = (data) => {
    return $get('/data/download/history/item/url', data, {
        queryName: 'dataManageDownloadUrlRequest',
    });
};

export const dataManageDownloadOverviewRequest = (data) => {
    return $post('/data/download/overview', data, {
        queryName: 'dataManageDownloadOverviewRequest',
    });
};

export const dataManageDownloadSubmitRequest = (data) => {
    return $post('/data/download/submit', data, {
        queryName: 'dataManageDownloadSubmitRequest',
    });
};

export const dataManageDownloadTemplateRequest = () => {
	return $get('/data/download/product/code/template', {}, {
		queryName: 'dataManageDownloadTemplateRequest',
		responseType: 'blob',
	})
}

export const dataManageDownloadProductCodeOverviewRequest = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key]) {
			formData.append(key, data[key]);
		}
	});
	return $post('/data/download/product/code/overview', formData, {
		queryName: 'dataManageDownloadProductCodeOverviewRequest',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const dataManageDownloadProductCodeUploadRequest = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key]) {
			formData.append(key, data[key]);
		}
	});
	return $post('/data/download/product/code/submit', formData, {
		queryName: 'dataManageDownloadProductCodeUploadRequest',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const dataManageSkuPpiExportDownloadSubmitRequest = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key]) {
			formData.append(key, data[key]);
		}
	});
	return $post('/data/download/sku-ppi/product-code/submit', formData, {
		queryName: 'dataManageSkuPpiExportDownloadSubmitRequest',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const dataManageSkuPpiExportOverview = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key]) {
			formData.append(key, data[key]);
		}
	});
	return $post('/data/download/sku-ppi/product-code/overview', formData, {
		queryName: 'dataManageSkuPpiExportOverview',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const dataManageUploadTemplateRequest = () => {
	return $get('/data/upload/nike/inventory/template', {}, {
		queryName: 'dataManageUploadTemplateRequest',
		responseType: 'blob',
	})
}

export const dataManageUploadRequest = (data) => {
	const formData = new FormData();
	Object.keys(data).forEach((key) => {
		if (data[key]) {
			formData.append(key, data[key]);
		}
	});
	return $post('/data/upload/nike/inventory/upload', formData, {
		queryName: 'dataManageUploadRequest',
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	})
}

export const dataManageUploadConfirmRequest = (data) => {
	return $post('/data/upload/nike/inventory/confirm', data, {
		queryName: 'dataManageUploadConfirmRequest'
	})
}

export const dataManageUploadCancelRequest = (data) => {
	return $post('/data/upload/nike/inventory/cancel', data, {
		queryName: 'dataManageUploadCancelRequest'
	})
}